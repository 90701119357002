
.slideshow {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-bottom: 50px;
    height: 100%;
    overflow: hidden;
  }
  
  .slick-initialized { overflow: hidden; }

  .slideshow img {
    max-width: 100%;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    border-top: 5px solid #FF0000;
    border-bottom: 2px solid #FF0000;
    min-height: 611px;
  }

  .line {
    height: 6px;
    max-width: 500px;
    background-color: red;
    width: 100%;
  }
  
  .lineBottom {
    height: 6px;
    max-width: 500px;
    background-color: red;
    width: 100%;
    margin-right: 0;
    margin-left: auto;
    margin-top: -5px;
  }


  .quote {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .small {
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    color: var(--light) !important;
    line-height: 48px;
    margin-bottom: 41px;
  }


  .quote p {
    font-size: 20px;
  }

  .quote p:nth-child(1) {
    font-size: 20px;
    font-weight: 600;
  }

  .text-container {
    display: flex; 
    align-items: flex-start; 
  }
  
  .horizontal-line {
    width: 120px; 
    height: 4px;
    background-color: #fff; 
    border: none; 
    margin-right: 23px;
    margin-top: 10px;
  }
  
  .text-left {
    text-align: left; 
    flex-grow: 1; 
  }

  @media screen and (max-width: 900px) {
    .small {
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        color: var(--light) !important;
        line-height: 34px;
    }
  }

  @media screen and (max-width: 500px) {
    .small {
        font-size: 28px;
    }
  }