@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url("https://use.typekit.net/lgf4wjv.css");


:root {
  --body-fonts:'Inter', sans-serif;
  --red: #F00;
  --body-color: #6F6F6F;
  --headline-color: #1B1B1B;
  --light: #fff;
}

.meme-font {
  font-family: "gigalypse", sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 20px;
  text-transform: uppercase;
}


.fadeIn {
	-webkit-animation: fade-in 1.2s ease-in both;
	        animation: fade-in 1.2s ease-in both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-2-2 17:51:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}