.logo_p {
    color: var(--headline-color);
    text-align: center;
}


.logo__section-container {
    margin-top: 45px;
}

.logo__section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1100px;
    margin: auto;
    padding: 3%;
}

.logo {
    margin: 10px;
}

.logo__section img:nth-child(1) {
    max-width: 200px;
}

.logo__section img:nth-child(2) {
    max-width: 143px;
}

.logo__section img:nth-child(3) {
    max-width: 126px;
}

.logo__section img:nth-child(4) {
    max-width: 222px;
}

.logo__hr {
    max-width: 845px;
    margin: auto;
    border:none;
    background-color: rgba(227, 225, 225, 0.569);
    height: 1px;
    margin-top: 25px;
}

.preview__top-container {
    min-height: 800px;
    display: flex;
    align-items: center;
    position: relative;
}

.preview__top-container > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1170px;
    margin: auto;
    padding: 25px;
}

.preview__top-container article, figure {
    width: 50%;
    padding: 10px;
    position: relative;
}

.preview__top-container article h2 {
    margin-bottom: 20px;
}

.preview__top-container article p {
    max-width: 500px;
}


.preview__top-container figure img { 
    width: 100%;
}

.circle_outline {
    position: absolute;
    z-index: -1;
}

.rightTri {
    z-index: -1;
    right: 0;
    middle:0;
    position: absolute;
}

@media (max-width: 1330px) {
    .preview__top-container {
        background-size: 60% !important;
    }
}

@media (max-width: 930px) {
    .preview__top-container article, figure {
        width: 100%;
    }
    .preview__top-container {
        background-size: 70% !important;
        background-position: bottom right !important;
    }
}


@media (max-width: 880px) {
    .logo {
        width: 100%;
    }

    .logo__section {
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .rightTri {
        bottom: 0;
        width: 50%;
    }
}