
.generator__container {
  padding: 10% 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #FFF;
    background: #000;
    color: var(--light);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    padding: 15px 20px;
    margin: auto;
    text-align: center;
    min-width: 213px;
  }

  .secondary__btn::after {
    content: url(/src/assets/right_arrow.svg);
    margin-left: 10px;
  }

  .generator__container h2 {
    text-align: center;
  }

  .generator_start {
    padding: 0 25px 25px;
  }

  .generator_start p {
    max-width: 700px;
    margin: auto;
    padding: 0 25px 25px;
    text-align: center;
  }

  .app__wrap {
    max-width: 1149px;
    width: 100%;
    padding: 25px;
  }

  .app__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid black;
    border-radius: 5px;
    padding: 80px 0 60px 0;
    max-width: 1149px;
    background: linear-gradient(138deg, rgba(255, 255, 255, 0.75) 55.94%, rgba(211, 211, 211, 0.75) 100%);
    position: relative;
    width: 100%;
    margin: 25px auto; /* Added auto margin to center horizontally */
  }
  
  .app__box::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    height: 4px;
    background-color: black;
    z-index: 1;
    margin-top: -2.5px;
  }

  .generator__container h2 {
    text-align: center;
    margin-bottom: 30px; /* Added margin to space out h2 from the line */
  }
  
  .poly {
    position: absolute;
    z-index: -10;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }