.form__section {
    position: relative;
}

.bgDecor {
    z-index: -1;
    position: absolute;
    right: 0;
    bottom: 0;
    /* width: 90%; */
}

.bgDecorBlack {
  z-index: -1;
  position: absolute;
  top: 250px;
  left: 200px;
  /* width: 90%; */
}


.form__container {
    border-radius: 15px;
    max-width: 1030px;
    background: linear-gradient(180deg, #121212 0%, #000 100%);
    margin: 15% auto 0; /* Remove the bottom margin */
    padding: 5% 5% calc(20% + 150px);
    text-align: center;
}

.form__container span {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.form__container p {
    color: #DADADA;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0 25px;
}

.form-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .form-field {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .form-field label {
    display: block; /* makes the label a block-level element */
  }

  .captcha {
    margin-left: 10px;
    margin-top: 10px;
  }

  label {
    text-align: left;
    margin-left: 10px;
    color: white;
  }
  
  .form__container input {
    height: 48px;
  }

  .form__container input, textarea {
    background-color: transparent;
    border: 1px solid #fff;
    padding: 15px 0px 0px 15px;
    margin: 10px;
    text-align: left;
    margin-bottom: 20px;
    color: white;
  }
  
  textarea {
    height: 153px;
  }

  button[type="submit"] {
    border-radius: 5px;
    border: 1px solid #FFF;
    background: #F00;
    padding: 10px 50px;
    color: #fff;
    display: flex;
    margin-left: 10px;
    margin-top: 10px;position: relative;
    z-index: 3;

  }

  .phone-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .iphones {
    width: 100%;
    max-width: 788px;
    position: relative;
    vertical-align: bottom;
  }

  @media (max-width: 1440px) {
    .bgDecorBlack {
      top: 150px;
      left: 140px;
      width: 80%;
    }
  }
  
  @media (max-width: 1150px) {
    .iphones {
        max-width: 80% !important;
    }
    .form__container {
        margin: 15% auto 80px;
     }

     .bgDecorBlack {
      max-width: 100%;
      left:0;
      top: 150px;
     }

     .bgDecor {
      max-width: 90%;
     }
  }

  @media (min-width: 768px) {
    .form-row {
      flex-direction: row;
    }
  }

  @media (max-width:768px) {
    .bgDecorBlack {
      top: 20px;
     }
  }
  
  @media (max-width: 500px) {
    .form__container {
        margin: 15% auto 0px;
     }
  }