.carousel-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
    margin-top: 150px;
    padding: 4%;
  }
  
  .carousel-slider {
    width: 40%;
  }
  
  .carousel-nav {
    width: 60%;
  }

  .caroursel-text {
    margin-bottom: 55px;
  }

  .caroursel-text h4 {
    margin-bottom: 15px;
  }
  
  .carousel-nav ul {
    list-style: none;
  }
  
  .carousel-nav li {
    cursor: pointer;
    font-size: 36px;
    font-weight: 600;
    color: #1B1B1B;
    margin-bottom: 18px;
    position: relative;
  }
  
  .carousel-nav li::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 65%;
    height: 4px;
    background-color: transparent;
    transition: background-color 0.3s ease;
    margin-left: -15px;
  }
  
  .carousel-nav li:hover::before,
  .carousel-nav li.active::before {
    background-color: red;
  }
  
  .carousel-slider img {
    cursor: pointer;
  }
  
  @media (max-width: 1100px) {
    .carousel-container {
      flex-direction: column;
      flex-flow: column-reverse;
    }
  
    .carousel-slider {
      margin-right: 0;
      margin-bottom: 20px;
      width: 100%;
    }
  
    .carousel-nav {
      width: 100%; /* Add this line */
      text-align: left;
      padding-left: 25px;
      padding-right: 25px;
    }
  
    .carousel-nav li {
      font-size: 24px; /* Reduce font size for smaller screens */
    }

    .carousel-slider img {
      margin-right: auto;
      padding-left: 15px;
      padding-top: 15px;
      width: 90%;
    }

    .nav-item {
      max-width: 400px;
      margin-right: auto;
    }

    .carousel-nav li::before {
      width: 100%;
      margin: auto;
    }

  .caroursel-text {
    margin-bottom: 45px;
    
  }
}
  