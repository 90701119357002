footer {
    display: flex;
    align-items: center;
    background-color: black;
    color: white;
    padding: 17px;
    justify-content: center;
}

footer p {
    text-align: center;
    font-size: 8px;
}