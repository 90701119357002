* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

button, a {
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--headline-color);
}

body {
  font-family: var(--body-fonts);
  color: var(--body-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

h1 {
  color: var(--light);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
}

h2 {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
}

h2 .large {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
}

h3 {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
}

h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

p {
  line-height: 26px;
}

section {
  padding: 6%;
}

.btn {
  border-radius: 5px;
  border: 1px solid #FFF;
  background: #000;
  color: var(--light);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  padding: 15px 55px;
  margin: 10px auto;
  text-align: center;
}

@media (max-width: 768px) {
    h1 {
      font-size: 30px;
    }

    h2 {
      font-size: 32px;
    }

    .form__container span {
      font-size: 30px !important;
    }
}


@media (max-width: 500px) {
  .preview-wrapper,
  .final__step-container {
      max-width: 300px;
  }
}