.card-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1200px;
    padding: 6% 46px;
  }
  
  .card {
    flex: 1 0 300px;
    margin: 15px;
    padding: 20px;
  }
  
  .card img {
    width: auto;
    height: 44px;
  }
  
  .card h2 {
    font-size: 24px;
    margin: 25px 0;
  }
  
  .card p {
    font-size: 16px;
    color: #666;
  }
  
  @media (max-width: 500px) {
    .card-section {
      padding: 6% 30px;
    }
  }