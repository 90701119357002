.card__select-container {
    text-align: center;
}

.cards__row {
    display: flex;
    max-width: 980px;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    padding: 25px;
}

.cards__card {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* overflow: hidden; */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5); /* Adjust shadow properties */
    transition: box-shadow 0.3s ease-in-out;
    margin: 5px;
    border-radius: 2px;
}

.cards__card:hover {
    cursor: pointer;
    border: 1px solid red;
    box-shadow: 4px 4px 15px 0px rgba(255, 0, 0, 0.25);
}

.cards__card:active {
    border: 1px solid red;
    box-shadow: 4px 4px 15px 0px rgba(255, 0, 0, 0.25);
}


.cards__card.active,
.cards__card:hover {
  border: 1px solid red;
  box-shadow: 4px 4px 15px 0px rgba(255, 0, 0, 0.25);
}

.frameDesk {
    max-width: 100%;
    height: auto;
    border-radius: 2px;
}


#frames {
    box-shadow: none !important;
    /* margin-left: 5px;
    margin-left: 5px; */
}

.cards__slide .cards__card {
    width: 100%;

}

#arrow {
    z-index: 300 !important;
}

#arrowR {
    z-index: 300 !important;
}



/* Update the slider settings */
.cards__slider .slick-slider {
    max-width: 372px;
    margin: auto;
    overflow: hidden; /* Add overflow hidden */
}

/* Update the individual card size */
.cards__slide .cards__card {
    width: 100%;
    box-sizing: border-box; /* Ensure padding and border don't affect the width */
    padding: 5px; /* Add padding for spacing between cards */
    margin: 0 !important; /* Remove margin to prevent visible adjacent cards */
}

/* Add overflow hidden to the slider container */
.cards__slider {
    overflow: hidden;
}

/* Apply the box shadow effect to slider cards only */
.cards__card-slider {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.3s ease-in-out;
    overflow: hidden;
  }
  
  .cards__card-slider:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }


.app__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width:768px) {
    .cards__slide .cards__card:hover {
        border: none;
        box-shadow:none;
    }
    
    .cards__slide .cards__card:active {
        border: none;
        box-shadow: none;
    }
    
}

@media screen and (max-width: 500px) {
    .cards__slider .slick-slider {
        max-width: 300px;
    }  

    .card__select-container p {
        max-width: 250px;
        margin: auto;
        margin-bottom: 20px;
    }

    #arrow {
        top: 125px !important;
    }

    #arrowR {
        top: 125px !important;
    }
}

