.hero {
    background-size: cover;
    background-position: center;
    min-height: 100vh;
  }

  /* img {
    width: 100%;
  } */

  .sandoval__logo {
    max-width: 107px;
  }

  .hero nav {
    padding: 2% 6%;
  }

  .hero__img {
    text-align: center;
  }

  .hero__img img {
    padding: 0 25px;
    max-width: 975px;
    width: 100%;
    margin: auto;
    margin-bottom: 60px;
  }

  .primary__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #FFF;
    box-shadow: 10px 6px 15px 0px rgba(0, 0, 0, 0.25);
    background: #000;
    color: var(--light);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    padding: 15px 20px;
    margin: 34px auto;
    text-align: center;
  }

  .primary__btn::after {
    content: url(/src/assets/downarrow.svg);
    margin-left: 10px;
  }

  .hero__content-container {
    padding: 2%;
    max-width: 1118px;
    margin: auto;
    text-align: center;
  }

  @media screen and (max-width:768px){
    .hero__content-container {
      padding: 12% 2% 2%;
    }
  }