.final__step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 600px;
    padding: 25px;
    margin: auto;
}

.final__step-container p {
    margin: 25px;
}

.final__step-container h4 {
    margin-top: 25px;
    font-size: 16px;
    font-weight: 700;
}

.final__image img {
    max-width: 372px;
    margin: auto;
    margin-bottom: 26px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
}

.download__btn {
    width: 200px;
    margin-top: 20px;
} 

.primary__btn-red {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #FFF;
    /* box-shadow: 10px 6px 15px 0px rgba(0, 0, 0, 0.25); */
    background: red;
    color: var(--light);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    padding: 15px 20px;
    margin: 34px auto;
    text-align: center;
    width: 200px;
  }

  .primary__btn-red::after {
    content: url(/src/assets/contactarrow.svg);
    margin-left: 10px;
  }

  @media (max-width: 500px) {
    .final__image img {
        max-width: 300px;
    }
  }