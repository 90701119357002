.preview-wrapper {
    max-width: 372px;
    margin: auto;
}

.preview {
    flex: 1;
}

.stage__container {
  padding-bottom: 15px;
}

.form-section {
    flex: 1;
    padding: 8px;
    text-align: center;
}

.image__btns-container {
    padding: 8px;
    text-align: center;
}

.image-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 15px 0;
}

  video {
    z-index: 100;
    border: 2px solid black;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5); /* Adjust shadow properties */
    transition: box-shadow 0.3s ease-in-out;
 }

 .webcam-square {
    width: 372px;
    height: 372px;
    object-fit: cover;
 }

 .btn-image {
    cursor: pointer;
    max-width: 150px; /* Adjust as needed */
    width: 100%; /* Adjust as needed */
    height: 80px;
  }
  
  #fileInput {
    display: none;
  }
  
  .text__input {
    border: 1.5px solid black;
    border-radius: 2px;
    width: 100%;
    height: 102px;
    margin: 20px 0;
    color: black;
    font-size: 16px; 
    max-width: 100%;
  }

  .form-section p {
    padding: 15px 0;
  }

  .stage {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  }

  .backbtn {
    background-color: transparent;
    border: none;
    outline: none;
    text-decoration: underline;
    color: black;
  }

  .chooseanother {
    margin-bottom: -3px;
    text-decoration: underline;
    padding-right: 5px;
  }


  @media screen and (max-width:500px) {
    .webcam-square {
      width: 300px;
      height: 300px;
    }
    .preview-wrapper {
      max-width: 300px;
    }

    .btn-image {
      width: auto;
    }

    .stage-container {
      max-width: 300px;
      margin: auto;
    }

    .konvajs-content {
      width: 300px !important;
      height: 300px !important;
    }

    canvas {
      width: 300px !important;
      height: 300px !important;
    }
  }